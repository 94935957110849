<template>
  <div class="self_answer">
    <button
      @click="openDialog"
      type="button"
      class="v-expansion-panel-header flex-row-reverse button_expand"
    >
      <div class="v-expansion-panel-header__icon">
        <i
          v-if="showImg"
          aria-hidden="true"
          class="v-icon notranslate ma-n3 mdi mdi-menu-down theme--light icon-show-img"
        >
        </i>
        <i
          v-else
          aria-hidden="true"
          class="v-icon notranslate ma-n3 mdi mdi-menu-up theme--light icon-show-img"
        >
        </i>
      </div>
      <div class="sub-title white--text ml-5"> {{text}} </div>
    </button>
    <div>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-card-text justify="center">
              <v-btn
                icon dark
                @click="dialog = false"
                color="black"
                class="icon-close-working"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <img
                class="processing_work"
                :src="src"
                alt="result graphics"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showImg: false,
      dialog: false,
      showDialog: false,
    };
  },
  props: {
    srcProcessing: {
      type: String,
    },
    text: {
      type: String,
    },
    src: {
      type: String,
    },
  },
  methods: {
    openDialog() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style lang="scss" scoped>
.button_expand {
  background-color: rgb(188, 136, 228);
  border-color: rgb(188, 136, 228);
  margin-bottom: 50px;
}
.v-expansion-panel-header {
  width: 100%;
  padding: 13px 10px;
}
.sub-title {
  font-size: 16px;
}
.v-application .flex-row-reverse {
  flex-direction: row !important;
}
.processing_work {
  width: 100%;
}
.icon-show-img{
  font-size: 50px;
  color: rgb(255, 255, 255);
  caret-color: rgb(255, 255, 255);
}
</style>
