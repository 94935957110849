<template>
  <div class="form-group" :class="{ 'form-group-error': validPhone.$error }">
    <vue-tel-input
      class="form__input-tel"
      id="telephone_number_main"
      :class="getClassByLengthCountryCode"
      :placeholder="placeHolder"
      :enabledCountryCode="enableCountryCode"
      :defaultCountry="defaultCountryCheck"
      :autoDefaultCountry="autoDefaultCountry"
      validCharactersOnly
      @input="changeTel"
      @country-changed="countryChanged"
      v-model="getPhone"
      :ignoredCountries="ignoredCountries"
      @focus="focusInput"
    >
      <template slot="arrow-icon">
        <span class="form__input-tel-arrow-icon"> </span>
      </template>
    </vue-tel-input>
    <template v-if="validPhone.$error">
      <div class="form__input-error" v-if="!validPhone.required">
        Field is required
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    diaCode: {
      type: String,
    },
    validPhone: {
      type: Object,
    },
    placeHolder: {
      type: String,
    },
    defaultCountry: {
      type: String,
    },
    enableCountryCode: {
      type: Boolean,
      default: true,
    },
    autoDefaultCountry: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    document.querySelector('#telephone_number_main .vti__input')
      .setAttribute('id', 'telephone_number');
  },
  computed: {
    defaultCountryCheck() {
      if (this.autoDefaultCountry) {
        return '';
      }
      return this.defaultCountry;
    },
    getClassByLengthCountryCode() {
      if (!this.enableCountryCode) {
        return 'code-length-0';
      }

      if (this.diaCode != null && this.diaCode && this.diaCode !== '') {
        return `code-length-${this.diaCode.length}`;
      }
      return 'code-length-0';
    },
    getPhone: {
      get() {
        return this.value;
      },
      set(newValue) {
        return this.$emit('input', newValue);
      },
    },
    ignoredCountries() {
      return ['EG', 'ID', 'CZ', 'RU', 'JP', 'KE', 'KZ', 'KW',
        'OM', 'PK', 'PH', 'SA', 'TZ', 'AE', 'VN'];
    },
  },
  methods: {
    changeTel(e, isValid) {
      this.$emit('input', isValid.number.input);
      // this.formData.phone = isValid.number.input;
    },
    countryChanged(data) {
      this.$emit('onDiaCode', data);
      // this.diaCode = data.dialCode;
    },
    focusInput() {
      if (!this.enableCountryCode && this.getPhone.length === 0 && this.getPhone === '') {
        this.getPhone = `+${this.diaCode}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.form__input-tel {
  font-family: $defaultFont;
  &.code-length {
    &-0 .vti__input {
      padding-left: 20px;
    }
    &-1 .vti__input {
      padding-left: 45px;
    }
    &-3 .vti__input {
      padding-left: 62px;
    }
    &-4 .vti__input {
      padding-left: 65px;
    }
  }
  .vti__dropdown-list {
    display: none !important;
  }
  &.vue-tel-input{
    background-color: #fff;
    border-radius: $formInputBorderRadius;
    border-color: $formInputBorderColor;
  }
  .vti__input{
    font-size: 16px;
    padding: 19px 10px 19px 52px;
    font-family: $defaultFont;
    border-radius: $formInputBorderRadius;
    color: $formInputColor;
    font-weight: bold;
    @media (max-height: $xsMaxHeight) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    @media (max-height: $xxsMaxHeight) {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 15px;
    }
    &::-webkit-input-placeholder {
      color: $formInputColor;
      font-weight: normal;
    }
    &::-moz-placeholder {
      color: $formInputColor;
      font-weight: normal;
    }
    &:-ms-input-placeholder {
      color: $formInputColor;
      font-weight: normal;
    }
    &:-moz-placeholder {
      color: $formInputColor;
      font-weight: normal;
    }
  }
  .vti__selection .vti__country-code{
    color: $formInputColor;
    font-size: 16px;
    font-family: $defaultFont;
    position: absolute;
    left: 114px;
    font-weight: bold;
  }
  .vti__dropdown.open{
    outline: none;
  }
  .vti__dropdown{
    min-width: 100px;
    border-right: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
  }
  .vti__selection{
    justify-content: center;
  }
  .form__input-tel-arrow-icon{
    position: relative;
    right: -10px;
    top: -5px;
    font-size: 16px;
    color: $formInputColor;
  }
  .vti__flag.gb{
    transform: scale(1.6);
    box-shadow: none;
  }
  .vti__dropdown-list{
    width: 300px;
    border-color: $formInputBorderColor;
  }
  .vti__dropdown-item{
    color: $txtColor2;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .vti__dropdown-item strong{
    font-weight: normal;
  }
  .vti__dropdown-item span{
    font-weight: bold;
  }
}
</style>
