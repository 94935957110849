<template>
  <div class="progress-bar d-flex align-center">
    <div class="progress-bar__value" :style="{ backgroundColor: `${color}4d` }">
      <div
        class="progress-bar__current-value"
        :style="{ width: getPercentByStep, backgroundColor: color }"
      ></div>
    </div>
    <div class="progress-bar__info sub-title">{{ value }}/{{ maxValue }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    maxValue: {
      type: [String, Number],
      default: 100,
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    getPercentByStep() {
      return `${this.value * 100 / this.maxValue}%`;
    },
  },
};
</script>
<style lang="scss" scoped>
.progress-bar {
  .progress-bar__value {
    width: 100%;
    height: 12px;
    background-color: $progressBarAllFieldBg;
    border-radius: 6px;
  }
  .progress-bar__current-value {
    background-color: $progressBarBg;
    height: 100%;
    border-radius: 6px;
    margin-right: auto;
  }
  .progress-bar__info {
    margin-left: 20px;
    white-space: nowrap;
  }
}
</style>
