<template>
  <v-app>
    <v-dialog
      v-model="dialog"
      :min-width="minWidth"
      :max-width="maxWidth"
      v-bind="$attrs"
    >
      <v-card>
        <v-card-title
          class="headline lighten-2"
          :class="{ 'red': type === 'error', 'orange': type === 'warning' }"
        >
          <slot name="inner-title"></slot>
          {{ title }}
        </v-card-title>
        <v-card-text v-if="message">
          <br />
          <h4>
            {{ message }}
          </h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="cancelBtn"
            color="primary"
            text
            @click="cancel"
          >
            {{ cancelBtn }}
          </v-btn>
          <v-btn
            v-if="confirmBtn"
            :disabled="disabledButton"
            color="primary"
            text
            @click="confirm"
          >
            {{ confirmBtn }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  props: {
    minWidth: {
      type: [Number, String],
      default: 200,
    },
    maxWidth: {
      type: [Number, String],
      default: 600,
    },
    type: String,
    title: String,
    message: String,
    disabledButton: Boolean,
    cancelBtn: {
      type: String,
      default: '',
    },
    confirmBtn: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.$emit('cancel');
      this.close();
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
