import http from '@utils/http';
import notifyError from '@helpers';

export default {
  fetchFAQ() {
    return new Promise((resolve, reject) => {
      http.get(`faq/${process.env.FAQ_ID}`).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        notifyError(error);
        reject(error);
      });
    });
  },

};
