const getters = {
  getAnswersAndUserId: state => state.answers,
};

const actions = {
  setAnswersAndUserId({ commit }, data) {
    commit('setAnswersAndUserId', data);
  },
  removeAnswersAndUserId({ commit }, data) {
    commit('removeAnswersAndUserId', data);
  },
};

const mutations = {
  setAnswersAndUserId(state, data) {
    if (state.answers[data.userId]) {
      state.answers[data.userId] = {
        ...state.answers[data.userId],
        ...data,
      };
    } else {
      state.answers[data.userId] = (data);
    }
  },
  removeAnswersAndUserId(state, data) {
    state.answers[data] = {};
  },
};

const state = {
  answers: {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
