<template>
  <div class="close-friend relative">
    <div
      class="
        u1_circle
        pa-7
        rounded-circle rounded-circle-friend
        d-inline-block
        rounded-circle-u1
      "
    >
      <p class="u1_circle_text">
        u1name
      </p>
    </div>
    <div class="u2-circle">
      <v-slider
        v-model="value"
        color="transparent"
        height="150"
        class="ma-0"
        thumb-color="#FF5151"
        thumb-label="always"
        track-color="transparent"
        :readonly="disabled"
      >
        <template v-slot:thumb-label>
          <span>YOU</span>
        </template>
      </v-slider>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: 100,
      disabled: false,
    };
  },
  watch: {
    value(newVal) {
      if (newVal < 80) {
        const valueHandle = 80 - newVal;
        const percentageMatch = valueHandle / 80 * 100;
        console.log('%', percentageMatch);
        setTimeout(() => {
          this.disabled = true;
        }, 2000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.close-friend {
  margin-top: 35px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 340px;
}
.rounded-circle {
  position: absolute;
}
.u1_circle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.u1_circle_text {
    padding-top: 65px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 500;
}
::v-deep.u2-circle {
  width: 190px;
  .v-slider__thumb-label-container {
    transform: translate(-50%, -50%);
  }
  .v-slider--horizontal {
    margin: 0;
  }
  .v-slider__thumb {
    left: 0;
    border: 3px solid #000 !important;
    transform: translate(-50%, -50%);
    &::before,
    &::after {
      display: none;
    }
  }
  .v-slider__thumb-label {
    color: $txtColor1;
    font-family: $titleFont;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    top: 20%;
    left: 50%;
    transform: translateX(-50%) rotate(45deg) !important;
  }
  .v-slider__thumb,
  .v-slider__thumb-label-container {
    width: 150px;
    height: 150px !important;
  }
}
</style>
